<template>
  <div class="container with-price-box">
    <div>
      <h1>Manually Sliced Ham from Iberico Pigs Fattened on Acorns</h1>
      <p>
        Envelopes with about 100g of thinly sliced cured ham.
      </p>
      <p>
        The pigs were raised on specialized pig feed for growth and fattened two times with acorns from our holm oak
        trees (Quercus ilex) while running around freely. The acorn fattening happens during the period of
        <em>montanera</em> which goes from November to March. There is no additional feed in that time. Instead the pigs
        feed on the acorns, which provide fat and other important nutrients, and eat grass and what else they can find
        on the land.
      </p>
      <p>
        Because of the sweet acorns the ham has a distinctive nutty flavor that resembles the flavor of
        the acorns themselves.
      </p>

      <picture>
        <a href="/img/shop/PlateOfHam-1080.jpg"><img src="@/assets/images/PlateOfHam-250.jpg" /></a>
      </picture>
      <p>
        After the butchering the ham is put into salt for several months and then cures for 3 years. During that process
        it will lose weight significantly and the flavor intensifies.
      </p>
      <p>See how it is cut in this video:</p>

      <CookieControlledContent>
        <iframe data-cy="youtubeVideo" class="youtube-video" width="460" height="258"
          src="https://www.youtube.com/embed/iEChpgH_4vU" title="YouTube video player" frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen></iframe>
      </CookieControlledContent>

      <p>
        The cured ham is sealed in vacuum and does not need refrigeration but should be kept in a cool place.
        The quantity of envelopes is limited. Please see what is still available to the right.
      </p>
      <p>
        Before consumption, open the bag and let it aerate a bit - just like a good wine. The slices are bite-size and
        traditionally are eaten just as they are at any time of the day. You will notice the nutty taste will stay in
        your mouth for a while which is desired and typical.
      </p>
    </div>
    <PriceBox product="605c6e77-e51b-45e1-aa9e-b32549db0152" />
  </div>
</template>

<script>
import PriceBox from '@/components/PriceBox.vue'
import CookieControlledContent from '@/components/CookieControlledContent.vue';

export default {
  name: "Shop",
  components: {
    PriceBox,
    CookieControlledContent
  },
}

</script>
